import React from 'react';
import Loadable from 'react-loadable';
import { Spin } from 'antd';

const styles = {
  center: {
    width: '100%',
    height: `calc(100vh - 64px)`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
};

export default component =>
  Loadable({
    loader: component,
    loading: () => {
      return (
        <div style={styles.center}>
          <Spin size="large" className="global-spin" />
        </div>
      );
    }
  });
