import { all, takeLatest, throttle, call, put } from 'redux-saga/effects';
import {
  QueryAccounts,
  QueryAccountUser,
  CreateAccount,
  CreateAccountUser
} from '../actions/account';
import {
  getAccounts,
  getAccountUsers,
  createTalent,
  createUser
} from '../../services/accountService';

function* fetchAccountsSaga({ payload }) {
  const { callback } = payload;
  try {
    const accounts = yield call(getAccounts);
    yield put(QueryAccounts.success({ accounts }));
    if (callback && callback.ok) {
      callback.ok();
    }
  } catch (error) {
    console.error(error);
    if (callback && callback.error) {
      callback.error();
    }
  }
}

function* fetchAccountUserSaga({ payload }) {
  const { id, callback } = payload;
  try {
    const users = yield call(getAccountUsers, id);
    if (callback && callback.ok) {
      callback.ok(users);
    }
  } catch (error) {
    console.error(error);
    if (callback && callback.error) {
      callback.error();
    }
  }
}

function* createAccountSaga({ payload }) {
  const { body, callback } = payload;
  try {
    yield call(createTalent, body);
    yield put(QueryAccounts.request({}));
    if (callback && callback.ok) {
      callback.ok();
    }
  } catch (error) {
    if (callback && callback.error) {
      callback.error();
    }
  }
}

function* createAccountUserSaga({ payload }) {
  const { id, body, callback } = payload;
  try {
    yield call(createUser, id, body);
    if (callback && callback.ok) {
      callback.ok();
    }
  } catch (error) {
    if (callback && callback.error) {
      callback.error();
    }
  }
}

export default function*() {
  yield all([
    takeLatest(QueryAccounts.REQUEST, fetchAccountsSaga),
    takeLatest(QueryAccountUser.REQUEST, fetchAccountUserSaga),
    throttle(1000, CreateAccount.REQUEST, createAccountSaga),
    throttle(1000, CreateAccountUser.REQUEST, createAccountUserSaga)
  ]);
}
