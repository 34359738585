import dynamicWrapper from '../utils/dynamicWrapper';

export const menus = [
  {
    key: 'account',
    name: '账号管理',
    icon: 'team',
    path: '/accounts',
    component: dynamicWrapper(() => import('../pages/account/index.js'))
  }
];
