import { QueryAccounts } from '../actions/account';

const initialState = {
  accounts: [],
  currentAccount: {}
};

export default function accountReducer(state = initialState, action) {
  switch (action.type) {
    case QueryAccounts.SUCCESS: {
      return { ...state, accounts: action.payload.accounts };
    }
    case 'SET_CURRENT_ACCOUNT': {
      return { ...state, currentAccount: action.payload.currentAccount };
    }
    default:
      return initialState;
  }
}
