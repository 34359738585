import _ from 'lodash';

class LocalCache {
  constructor(repository) {
    this.repository = repository;
  }

  save(key, value) {
    this.repository.setItem(key, JSON.stringify(value));
  }

  get(key) {
    return this.repository.getItem(key);
  }

  getAs(key, model) {
    const cache = this.repository.getItem(key);
    return !_.isEmpty(cache) ? model.load(cache) : cache;
  }

  delete(key) {
    this.repository.removeItem(key);
  }
}

export default new LocalCache(localStorage);
