import { applyMiddleware, compose, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';
import { localStorageMiddleware } from './middleware';
import createRootReducer from './reducer';

const win = window;
export const history = createBrowserHistory();
export const sagaMiddleware = createSagaMiddleware();
const MyrouterMiddleware = routerMiddleware(history);

const middleWares = applyMiddleware(
  MyrouterMiddleware,
  sagaMiddleware,
  localStorageMiddleware
);
const enhancer = compose(
  middleWares,
  process.env.NODE_ENV === 'development' && (win && win.devToolsExtension)
    ? win.devToolsExtension()
    : f => f
);

export const store = createStore(createRootReducer(history), {}, enhancer);
