import React from 'react';
import logo from '../../../assets/img/logo_text.svg';
import styles from './SiteLogo.module.scss';

const SiteLogo = () => (
  <div className={styles.top}>
    <div className={styles.header}>
      <img alt="logo" className={styles.logo} src={logo} />
    </div>
    <div className={styles.subTitle}>租户管理平台</div>
  </div>
);

export default SiteLogo;
