import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import rootSaga from './redux/sagas';
import { store, history, sagaMiddleware } from './redux/store';
import Authorized from './utils/authorized';
import { setToken } from './utils/fetch';
import { getURLParameters } from './utils/utils';
import { MainLayout } from './layouts';
import Login from './pages/user/LoginPage';

const { AuthorizedRoute } = Authorized;

class App extends Component {
  constructor(props) {
    super(props);
    const urlParameters = getURLParameters(window.location.href);
    console.log('app starting');
    if (urlParameters.token) {
      setToken(urlParameters.token);
    }
    sagaMiddleware.run(rootSaga);
  }

  render() {
    return (
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <Switch>
            <Route path="/login" component={Login} />
            <AuthorizedRoute
              path="/"
              render={props => <MainLayout {...props} />}
              authority={['admin', 'user']}
              redirectPath="/login"
            />
          </Switch>
        </ConnectedRouter>
      </Provider>
    );
  }
}

export default App;
