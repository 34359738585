import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import user from './user';
import account from './account';

export default history =>
  combineReducers({
    router: connectRouter(history),
    user,
    account
  });
