import { all, call, put, throttle, takeLatest } from 'redux-saga/effects';
import { replace } from 'connected-react-router';
import _ from 'lodash';
import { message } from 'antd';
import { LOGIN, LOGOUT } from '../actions/user';
import { QueryAccounts } from '../actions/account';
import userCache, { User } from '../../utils/userCache';
import { reloadAuthorized } from '../../utils/authorized';
import { createToken, queryCurrent } from '../../services/userService';

function* loginSaga({ payload }) {
  try {
    const { json } = yield call(createToken, payload);
    console.log(json);
    if (json && _.includes(json.roles, 'ROLE_JARVIS_ADMIN')) {
      const user = yield call(queryCurrent, json);
      yield put(LOGIN.success({ user: user.json, token: json.accessToken }));
      userCache.cache(User.parse(user.json));
      reloadAuthorized();
      yield put(replace('/home'));
    } else {
      const error = new Error();
      error.message = '当前账号没有登陆权限!';
      throw error;
    }
  } catch (e) {
    yield put(LOGIN.failure({ e }));
    message.error(e.message || '登陆失败');
  }
}

function* logoutSaga() {
  try {
    console.log('logout');
    yield put(QueryAccounts.success({ accounts: [] }));
    yield put(LOGOUT.success());
    reloadAuthorized();
    yield put(replace('/login'));
  } catch (error) {
    console.log(error);
  }
}

export default function* watchUser() {
  yield all([
    throttle(1000, LOGIN.REQUEST, loginSaga),
    takeLatest(LOGOUT.REQUEST, logoutSaga)
  ]);
}
