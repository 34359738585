import { LOGIN, REGISTER } from '../actions/user';

const initialState = {
  isLoggingIn: false,
  isAuthenticated: false,
  user: undefined,
  authError: false,
  authed: true
};

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case LOGIN.REQUEST:
      return Object.assign({}, state, { isLoggingIn: true });
    case LOGIN.FAILURE:
      return Object.assign({}, state, { isLoggingIn: false, authError: true });
    case LOGIN.SUCCESS:
      return Object.assign({}, state, {
        user: action.payload.user,
        isLoggingIn: false,
        authError: false
      });
    case REGISTER.REQUEST:
      return Object.assign({}, state, { isRegistering: true });
    case REGISTER.FAILURE:
      return Object.assign({}, state, { isRegistering: false });
    case REGISTER.SUCCESS:
      return Object.assign({}, state, { isRegistering: false });
    default:
      return initialState;
  }
}
