export const strings = {
  ERROR_NOT_VALID_RESPONSE_TYPE: '无效请求返回类型',
  ERROR_UNKNOWN_ERROR: '未知错误',
  // Response status 4xx
  RESPONSE_BAD_REQUEST: '无效的网络请求',
  RESPONSE_UNAUTHORIZED: '登录信息无效，请重新登录',
  RESPONSE_PAYMENT_REQUIRED: '余额不足，请充值后再尝试操作',
  RESPONSE_FORBIDDEN: '无权进行该操作',
  RESPONSE_NOT_FOUND: '未找到所需资源',
  RESPONSE_METHOD_NOT_ALLOWED: '无效的网络请求',
  RESPONSE_CONFLICT: '当前资源已存在',

  // Response Status 5xx
  RESPONSE_INTERNAL_SERVER_ERROR: '服务器未知错误',
  RESPONSE_SERVICE_UNAVAILABLE: '服务器开小差了，请稍后再试',
  RESPONSE_GATEWAY_TIMEOUT: '网关超时',

  // Response Status 200 & code != 0
  RESPONSE_ERROR: '请求错误',
  RESPONSE_TOKEN_EXPIRED: '登录失效，请重新登录'
};

export const codes = {
  101: '网关不存在',
  102: '网关升级中',
  801: '无效请求：请求版本低于设备运行版本',
  802: '网络传输错误',
  803: '设备错误：Out of Memory',
  901: 'checksum出错',
  1001: '文件格式错误',
  1101: '配置文件读取失败',
  1102: '类型参数错误',
  9999: '未知错误'
};
