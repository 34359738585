import { Link } from 'react-router-dom';
import React, { Fragment, PureComponent } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { LogoutMenu } from '../ActionMenu';
import { push } from 'connected-react-router';
import { LOGOUT } from '../../redux/actions/user';
import logo from '../../assets/img/logo.png';

class TopNavHeader extends PureComponent {
  onMenuClick = e => {
    console.log(e.key);
    if (e.key === 'logout') {
      this.props.logout({});
    }
  };
  render() {
    return (
      <Fragment>
        <nav className="row align-justify topbar">
          <div className="logo" key="logo" id="logo">
            <Link to="/">
              <img src={logo} alt="logo" />
            </Link>
            <h3 className="site-title">租户管理平台</h3>
          </div>
          <div className="topbar__right">
            <LogoutMenu onClick={this.onMenuClick} />
          </div>
        </nav>
      </Fragment>
    );
  }
}

function mapStateToProps() {
  return {};
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      push,
      logout: LOGOUT.request
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TopNavHeader);
