import { strings } from './string';
import { message } from 'antd';
import toQuery from './toQuery';
import { LOGOUT } from '../../redux/actions/user';
import { store } from '../../redux/store';
import setting from '../../setting';

const methods = ['get', 'post', 'put', 'patch', 'delete', 'download'];

let token = window.localStorage.getItem('jwt');

function handleDownload({ response, params }) {
  const { fd, td } = params;
  response.blob().then(blob => {
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `产量报表${fd}-${td}.xlsx`;
    a.click();
  });
}

function handleError(e) {
  const { dispatch } = store;
  const status = e.name;
  if (status === 403) {
    dispatch(LOGOUT.request());
    message.warn('登录过期，请重新登录');
    return Promise.reject({ msg: strings.RESPONSE_TOKEN_EXPIRED });
  }
  let msg = '未知错误';
  if (e.json) {
    return e.json.then(json => {
      const msg1 = json.message || msg;
      message.error(msg1);
      return Promise.reject({ error: msg1 });
    });
  }
  message.error(msg);
  return Promise.reject({ error: msg });
}

function checkStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }
  const error = new Error();
  error.name = response.status;
  error.url = response.url;
  if (response.json) {
    error.json = response.json();
  }
  throw error;
}

async function getJSON(response) {
  if (response.status === 204) return {};
  return response.json();
}

function convertToJson(response, method) {
  switch (method) {
    case 'POST':
      return getJSON(response)
        .then(json => ({ json }))
        .catch(() => {
          const location = response.headers.get('location');
          let res = { status: 'ok' };
          if (location) {
            res = {
              id: location.substring(location.lastIndexOf('/') + 1)
            };
          }
          return new Promise(callback => callback({ json: res }));
        });
    case 'DELETE':
      return {
        json: {
          status: 'ok'
        }
      };
    case 'PATCH':
      return response
        .json()
        .then(json => ({ json }))
        .catch(() => {
          return new Promise(callback =>
            callback({
              json: {
                status: 'ok'
              }
            })
          );
        });

    default:
      return getJSON(response).then(json => ({ json }));
  }
}

function fetchCreator(method) {
  return (url, { body, params, ...options } = {}) => {
    const fetchOptions = options;
    fetchOptions.method = method.toUpperCase();
    if (fetchOptions.method === 'download') {
      fetchOptions.method = 'get';
    }
    fetchOptions.headers = fetchOptions.headers || {};
    if (method !== 'download') {
      fetchOptions.headers.Accept = 'application/json';
      fetchOptions.headers['Content-Type'] = 'application/json';
    }
    if (token) {
      fetchOptions.headers['Access-Token'] = token;
    }
    let urlWithParams = `${setting.apiPrefix}${url}`;
    if (params) {
      urlWithParams += toQuery(params);
    }
    if (body) {
      if (!options.file) {
        fetchOptions.body = JSON.stringify(body);
      } else {
        fetchOptions.body = body;
        delete fetchOptions.headers['Content-Type'];
      }
    }
    if (method === 'download') {
      return fetch(urlWithParams, fetchOptions)
        .then(response => {
          return { response, params };
        })
        .then(handleDownload);
    }
    return fetch(urlWithParams, fetchOptions)
      .then(checkStatus)
      .then(response => convertToJson(response, method.toUpperCase()))
      .catch(handleError);
  };
}

class ApiClient {
  constructor() {
    methods.forEach(method => {
      this[method] = fetchCreator(method);
    });
  }
}

const fetchClient = new ApiClient();

const setToken = _token => {
  token = _token;
};

const getToken = () => token;

export default fetchClient;

export { setToken, getToken };
