import { getURLParameters } from './utils';
const defaultAuthority = 'admin';
const authorityKey = 'thingworks-authority';
export function getAuthority() {
  const urlParameters = getURLParameters(window.location.href);
  if (localStorage.getItem(authorityKey)) {
    return localStorage.getItem(authorityKey);
  } else if (urlParameters.token) {
    return 'admin';
  }
  return 'guest';
}

export function setAuthority(authority = defaultAuthority) {
  return localStorage.setItem(authorityKey, authority);
}

export function clearAuthority() {
  return localStorage.removeItem(authorityKey);
}
