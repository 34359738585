import { createAction } from 'redux-saga-actions';

export const LOGIN = createAction('LOGIN');
export const LOGOUT = createAction('LOGOUT');
export const REGISTER = createAction('REGISTER');
export const QueryAllMeta = createAction('LOAD_ALL_META_DATA');

export const USER_AUTHENTICATE = createAction('USER_AUTHENTICATE');
export const USER_REDIRECT_AUTHENTICATE = createAction(
  'USER_REDIRECT_AUTHENTICATE'
);
