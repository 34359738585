import React, { Component } from 'react';
import _ from 'lodash';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Layout, Menu } from 'antd';
import { Switch, Redirect, Route } from 'react-router-dom';
import Authorized from '../utils/authorized';
import dynamicWrapper from '../utils/dynamicWrapper';
import TopNavHeader from '../components/TopNavHeader';
import { menus } from '../common/menu';
import { QueryAccounts } from '../redux/actions/account';
import { push } from 'connected-react-router';
const { Sider } = Layout;
const { AuthorizedRoute } = Authorized;

class MainLayout extends Component {
  componentDidMount() {
    this.props.loadAccounts({});
  }

  navigate = path => () => {
    this.props.push(path);
  };

  render() {
    return (
      <Layout style={{ minHeight: '100vh' }}>
        <TopNavHeader />
        <Layout>
          <Sider width={200} style={{ background: '#fff' }}>
            {/* <div
              onClick={this.navigate('/accounts')}
              className="menu-item-vertical active"
              style={{ paddingLeft: 48 }}
            >
              租户管理
            </div> */}
            <Menu>
              <Menu.Item key="account">
                <div onClick={this.navigate('/accounts')}>租户管理</div>
              </Menu.Item>
              <Menu.Item key="notification">
                <div onClick={this.navigate('/notification')}>系统公告管理</div>
              </Menu.Item>
            </Menu>
          </Sider>

          <Layout style={{ padding: '0 24px 24px' }}>
            <Switch>
              {_.map(menus, ({ key, path, component, submenu }) => {
                if (_.isArray(submenu) && submenu.length > 0) {
                  return _.map(submenu, sub => (
                    <AuthorizedRoute
                      exact={true}
                      key={sub.key}
                      path={sub.path}
                      component={sub.component}
                    />
                  ));
                }
                return (
                  <AuthorizedRoute
                    exact={true}
                    key={key}
                    path={path}
                    component={component}
                    redirectPath="/exception/403"
                  />
                );
              })}
              <AuthorizedRoute
                exact={true}
                path={'/accounts/:id/users'}
                component={dynamicWrapper(() =>
                  import('../pages/account/AccountDetail.js')
                )}
              />
              <Redirect exact={true} from="/" to="/accounts" />
            </Switch>

            <Switch>
              <Route
                path="/notification"
                component={dynamicWrapper(() =>
                  import('../pages/notification/index.js')
                )}
              ></Route>
            </Switch>
          </Layout>
        </Layout>
      </Layout>
    );
  }
}
function mapStateToProps() {
  return {};
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      push,
      loadAccounts: QueryAccounts.request
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(MainLayout);
