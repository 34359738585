import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Alert } from 'antd';
import { LOGIN } from '../../redux/actions/user';
import Login from '../../components/Login';
import styles from './index.module.scss';
import SiteLogo from './Common/SiteLogo';

const { UserName, Password, Submit } = Login;

class LoginPage extends Component {
  handleSubmit = (err, values) => {
    if (!err) {
      this.props.login({ ...values });
    }
  };

  renderMessage = content => {
    return (
      <Alert
        style={{ marginBottom: 24 }}
        message={content}
        type="error"
        showIcon
      />
    );
  };

  render() {
    const { login, logining } = this.props;
    return (
      <div className={styles.container}>
        <div className={styles.content}>
          <SiteLogo />
          <div className={styles.main}>
            <Login onSubmit={this.handleSubmit}>
              <div>
                {login.error &&
                  !login.submitting &&
                  this.renderMessage('账户或密码错误')}
                <UserName name="username" placeholder="用户名 或者 手机号" />
                <Password name="password" placeholder="密码" />
              </div>
              <Submit loading={logining}>登录</Submit>
            </Login>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state;
  return {
    logining: user.isLoggingIn,
    authError: user.authError
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      login: LOGIN.request
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginPage);
