export default function toQuery(params = {}) {
  const esc = encodeURIComponent;
  const query = Object.keys(params)
    .map(k => {
      if (Array.isArray(params[k])) {
        return params[k].map(v => esc(k) + '=' + esc(v)).join('&');
      }
      return esc(k) + '=' + esc(params[k]);
    })
    .join('&');
  return `?${query}`;
}
