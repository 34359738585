import { LOGIN, LOGOUT } from './actions/user';
import { setToken } from '../utils/fetch';
import userCache from '../utils/userCache';
import { setAuthority, clearAuthority } from '../utils/authority';

const localStorageMiddleware = store => next => action => {
  if (action.type === LOGIN.SUCCESS) {
    if (!action.error) {
      setAuthority();
      localStorage.setItem('jwt', action.payload.token);
      setToken(action.payload.token);
    }
  } else if (action.type === LOGOUT.SUCCESS) {
    setToken(null);
    clearAuthority();
    userCache.clear();
    localStorage.removeItem('jwt');
  }
  next(action);
};

export { localStorageMiddleware };
