import fetchClient from '../utils/fetch';

export function createTalent(body) {
  return fetchClient.post('/tenants', {
    body
  });
}

export function createUser(id, body) {
  if (!id) return;
  return fetchClient.post(`/tenants/${id}/users`, {
    body
  });
}

export function updateUser(id, userId, body) {
  if (!id || !userId) return;
  return fetchClient.put(`/users/${userId}/password`, {
    body
  });
}

export function deleteUser(id, userId) {
  console.log(id, userId);
  if (!id || !userId) return Promise.reject();
  return fetchClient.delete(`/tenants/${id}/users/${userId}`);
}

export async function getAccounts() {
  const { json } = await fetchClient.get('/tenants');
  return json;
}

export async function getAccountUsers(id) {
  if (!id) return;
  const { json } = await fetchClient.get(`/tenants/${id}/users`);
  return json;
}

export async function getFeaturesByTenantId(tenantId) {
  const { json } = await fetchClient.get(`/tenants/${tenantId}/features`);
  return json;
}

export async function updateFeaturesByTenantId(tenantId, body) {
  return fetchClient.put(`/tenants/${tenantId}/features`, {
    body
  });
}

export async function getFeaturesComponentsByTenantId(tenantId) {
  const { json } = await fetchClient.get(
    `/tenants/${tenantId}/features/components`
  );
  return json;
}
