import { createAction } from 'redux-saga-actions';

export const QueryAccounts = createAction('QUERY_ACCOUNTS');
export const CreateAccount = createAction('CREATE_ACCOUNT');
export const QueryAccountUser = createAction('QUERY_ACCOUNT_USER');
export const CreateAccountUser = createAction('CREATE_ACCOUNT_USER');

export const UpdateAccount = createAction('UPDATE_ACCOUNT');
export const DeleteAccount = createAction('DELETE_ACCOUNT');

export function setCurrentAccount(payload) {
  return { type: 'SET_CURRENT_ACCOUNT', payload };
}
