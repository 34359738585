import _ from 'lodash';
import localCache from './localCache';

const KEY = '77B44137-2C32-46EA-AB31-732197E482B6';

class UserCache {
  constructor(cache) {
    this.localCache = cache;
  }

  cache(user) {
    this.localCache.save(KEY, user);
  }

  get() {
    const user = this.localCache.getAs(KEY, User);
    return _.isEmpty(user) ? new User({}) : user;
  }

  clear() {
    this.localCache.delete(KEY);
  }
}

export default new UserCache(localCache);

export class User {
  constructor(json) {
    this.username = json.username;
    this.fullName = json.fullName;
    this.phoneNumber = this.phoneNumber;
  }

  getDisplayName() {
    return this.fullName
      ? this.fullName
      : this.username
      ? this.username
      : '用户';
  }

  static parse(json) {
    return new User(json);
  }

  static load(cache) {
    return User.parse(JSON.parse(cache));
  }
}
