import React from 'react';
import { Dropdown, Icon, Menu } from 'antd';
import userCache from '../utils/userCache';

export const LogoutMenu = ({ onClick }) => {
  const displayName = userCache.get()
    ? userCache.get().getDisplayName()
    : '用户';
  const menu = (
    <Menu onClick={onClick}>
      <Menu.Item key="logout">
        <Icon type="logout" />
        退出登录
      </Menu.Item>
    </Menu>
  );

  return (
    <Dropdown overlay={menu} placement="bottomCenter">
      <div style={{ fontSize: 16 }}>
        <Icon type="user" />
        <span className="topbar__action">
          <span>{displayName}</span>
        </span>
      </div>
    </Dropdown>
  );
};
