import fetchClient from '../utils/fetch';

export async function createToken(credentials) {
  return fetchClient.post('/tokens', {
    body: credentials
  });
}

export async function createUser(registration) {
  return fetchClient.post('/users', {
    body: {
      fullName: registration.fullName,
      phoneNumber: registration.phoneNumber,
      password: registration.password
    }
  });
}

export async function queryCurrent(token) {
  return fetchClient.get(`/users/${token.userId}`, {
    headers: {
      'Access-Token': token.accessToken
    }
  });
}
